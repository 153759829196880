.table-header{
	padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-header > *{
	margin-bottom: 1rem;

}



.table-header > input{
	flex: 1;
	max-width: 100%;
	padding: 1rem;
	margin-bottom: 1rem;

}


@media (min-width: 600px) {
  .table-header {
 	flex-direction: row;
 	justify-content: space-between;
   }
}
   
